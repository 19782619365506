import React from 'react'
import AccountVerification from './settingComponents/AccountVerification'
const Setting = () => {
  return (
    <div>
          <h3 className='mb-10'>Settings</h3>
          <AccountVerification/>
    </div>
  )
}

export default Setting