import React from 'react'
import {BsCheck2Circle} from 'react-icons/bs'
const Pending = () => {
  return (
    <div className='flex gap-2 items-center'>
          <span className="icon-h4">
        <BsCheck2Circle />
      </span>
          <span className=' font-extrabold'>Your request has been submited.




</span>
    </div>
  )
}

export default Pending