import React from 'react'

export default function PreLoader() {
  return (
          <div className="preloader">
          <div className="plc">
          <div className="plro">
              
          </div>
          <h3>Checking...</h3>
          </div>
      </div>
  )
}
